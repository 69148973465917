import { AdvertContactDetails, getAdvertContactValue } from '@bbx/common/types/ad-detail/AdvertContactDetails'
import { LinkWithButtonStyle } from '@wh-components/core/Button/LinkWithButtonStyle'
import { logBdsEvent } from '@wh/common/chapter/api/bdsApiClient'
import { DmpStateContext } from '@wh/common/chapter/components/DmpStateProvider/DmpStateProvider'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import React, { FunctionComponent, useContext } from 'react'
import CallIcon from '@wh-components/icons/Call'
import { useIsAndroidBrowser } from '@wh/common/chapter/components/AndroidOnelinkBanner/useAndroidOnelinkBanner'
import { useOptimizelyTrack } from '@wh/common/chapter/hooks/optimizely'

type StickyFooterPhoneNumberButtonProps = {
    adId: string
    taggingData: TaggingData
    optimizelyTrackEvent?: string
    advertContactDetails: AdvertContactDetails
    onAdvertisingStateContacted?: () => void
}

export const StickyFooterPhoneNumberButton: FunctionComponent<StickyFooterPhoneNumberButtonProps> = ({
    adId,
    advertContactDetails,
    taggingData,
    optimizelyTrackEvent,
    onAdvertisingStateContacted,
}) => {
    const { trackCustomDmpEvent } = useContext(DmpStateContext)
    const phoneNo = getAdvertContactValue(advertContactDetails, 'phoneNo')
    const phoneNo2 = getAdvertContactValue(advertContactDetails, 'phoneNo2')

    const isAndroidBrowser = useIsAndroidBrowser()
    const trackEvent = useOptimizelyTrack({ isAndroid: isAndroidBrowser })

    if (!phoneNo && !phoneNo2) {
        return null
    }

    return (
        <LinkWithButtonStyle
            size="large"
            href="#"
            aria-label="Anbieter Anrufen"
            flex="1 1 44%"
            marginRight="s"
            testId="ad-detail-footer-sticky-phone-number"
            onClick={() => {
                window.open(`tel:${phoneNo ?? phoneNo2}`, '_self')
                trackCustomDmpEvent('UserAction', { eventName: 'TapCallSeller' })
                if (onAdvertisingStateContacted) {
                    onAdvertisingStateContacted()
                }
                logBdsEvent(adId, 'call')
                if (optimizelyTrackEvent) {
                    trackEvent(optimizelyTrackEvent)
                }
                return callActionEvent('addetail_call_phone_click', taggingData)
            }}
        >
            <CallIcon size="medium" color="semantic.foreground.white" marginRight={1} />
            Anrufen
        </LinkWithButtonStyle>
    )
}
