import { AdvertStatus, ContactType, isContactPossible } from '@bbx/common/types/ad-detail/AdvertDetails'
import { Button, ButtonProps } from '@wh-components/core/Button/Button'
import { TestProps } from '@wh-components/core/common'
import { useResponsiveValue } from '@wh-components/core/utilities/responsive'
import EmailIcon from '@wh-components/icons/Email'
import MessageIcon from '@wh-components/icons/Message'
import { SpaceProps } from '@wh-components/system/space'
import { useIsAndroidBrowser } from '@wh/common/chapter/components/AndroidOnelinkBanner/useAndroidOnelinkBanner'
import { DmpStateContext } from '@wh/common/chapter/components/DmpStateProvider/DmpStateProvider'
import { printButtonTextColorFirefoxWorkaroundCss } from '@wh/common/chapter/components/Printing/printButtonTextColorFirefoxWorkaroundCss'
import { useOptimizelyTrack } from '@wh/common/chapter/hooks/optimizely'
import { scrollToElement, tryFocus } from '@wh/common/chapter/lib/commonHelpers'
import { adContactIdSelectors, FIXED_HEADER_HEIGHT_PHONE } from '@wh/common/chapter/lib/config/constants'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import React, { Fragment, FunctionComponent, PropsWithChildren, useContext, useState } from 'react'
import { FacebookPixelScrollToMessaging } from '@bbx/common/components/FacebookPixel/FacebookPixel'

interface ContactButtonProps extends TestProps, SpaceProps, ButtonProps, PropsWithChildren {
    contactType: ContactType
    eventName?: TaggingActionEvent
    taggingData: TaggingData
    optimizelyTrackEvent?: string
    adStatus: AdvertStatus
    scrollLogicalPosition?: ScrollLogicalPosition
    disabled?: boolean
    type?: 'ICON' | 'ICON_WITH_LABEL'
    adId: string
    orgId: number
    verticalId: number
}

export const ContactButton: FunctionComponent<ContactButtonProps> = ({
    verticalId,
    size = 'large',
    scrollLogicalPosition = 'center',
    eventName = 'addetail_contact_seller_top_click',
    contactType,
    taggingData,
    optimizelyTrackEvent,
    adStatus,
    type = 'ICON_WITH_LABEL',
    adId,
    orgId,
    children,
    ...props
}) => {
    const { trackCustomDmpEvent } = useContext(DmpStateContext)

    const isAndroidBrowser = useIsAndroidBrowser()
    const [renderFacebookPixel, setRenderFacebookPixel] = useState(false)
    const trackEvent = useOptimizelyTrack({ isAndroid: isAndroidBrowser })
    const contactWording = children ? children : contactType === 'MESSAGING' ? 'Nachricht' : 'Verkäufer kontaktieren'
    const headerHeight = useResponsiveValue({ phone: FIXED_HEADER_HEIGHT_PHONE, tablet: 100 }, 0)
    if (!isContactPossible(adStatus)) {
        return null
    }

    return (
        <Fragment>
            {renderFacebookPixel && <FacebookPixelScrollToMessaging adId={adId} orgId={orgId} verticalId={verticalId} />}
            <Button
                size={size}
                Icon={contactType === 'MESSAGING' ? MessageIcon : EmailIcon}
                display={{ print: 'none' }}
                testId="contact-box-contact-button"
                onClick={() => {
                    const offsetTop = headerHeight
                    scrollToElement(document.getElementById('ad-contact-form-container'), scrollLogicalPosition, 'always', offsetTop)
                    tryFocus(adContactIdSelectors)
                    trackCustomDmpEvent('UserAction', { eventName: 'TapSendMessage' })
                    setRenderFacebookPixel(true)
                    if (optimizelyTrackEvent) {
                        trackEvent(optimizelyTrackEvent)
                    }
                    return callActionEvent(eventName, taggingData)
                }}
                {...props}
                css={printButtonTextColorFirefoxWorkaroundCss}
            >
                {type === 'ICON_WITH_LABEL' && contactWording}
            </Button>
        </Fragment>
    )
}
