import React, { FunctionComponent } from 'react'
import { GoogleConversionTrackingPixel } from '@wh/common/chapter/components/GoogleConversionTrackingPixel/GoogleConversionTrackingPixel'

interface GoogleConversionTrackingPixelProps {
    verticalId: number | undefined
}

// Labels provided by Pierre Kelz-Flitsch
const conversionLabels: { [key: number]: string } = {
    2: 'T0W3COjXhN8BEN7ms4AD', // Real Estate
    3: '6pfVCJmYm_MBEN7ms4AD', // AM
    5: 's8xrCJbck_YZEN7ms4AD', // BAP
}

export const GoogleConversionTrackingPixelContacted: FunctionComponent<GoogleConversionTrackingPixelProps> = ({ verticalId }) => {
    if (!verticalId) {
        return null
    }
    const conversionLabel = conversionLabels[verticalId]

    return <GoogleConversionTrackingPixel conversionId="806155102" conversionLabel={conversionLabel} />
}
