import React, { Fragment, FunctionComponent } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import TooltipQuestionmark from '@wh-components/icons/TooltipQuestionmark'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { Tooltip } from '@wh-components/core/Tooltip/Tooltip'
import { Text } from '@wh-components/core/Text/Text'

export const PayliveryPriceBoxQuestionMarkTooltip: FunctionComponent<{ testIdPrefix: string }> = ({ testIdPrefix }) => {
    return (
        <Box display="flex" fontSize="s" color="palette.raccoon" testId={`${testIdPrefix}-price-box-paylivery-info-label`}>
            Sicher kaufen mit Käuferschutz
            <Tooltip
                interactive={true}
                content={
                    <Fragment>
                        <Text as="p" marginBottom="m">
                            Ist der Artikel nicht wie beschrieben oder wurde nicht verschickt, bekommst du dein Geld zurück!
                        </Text>
                        <Text as="p">
                            <ServerRoutingAnchorLink
                                type="anchor"
                                target="_blank"
                                href="https://hilfe.willhaben.at/hc/de/articles/360011484279"
                            >
                                Mehr Informationen
                            </ServerRoutingAnchorLink>
                        </Text>
                    </Fragment>
                }
            >
                <Box display="flex" marginLeft="xs">
                    <TooltipQuestionmark color="palette.raccoon" size="xsmall" aria-hidden={true} />
                </Box>
            </Tooltip>
        </Box>
    )
}
