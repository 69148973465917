import React from 'react'
import { Thumbnail } from '@bbx/search-journey/common/components/ImageGallery/Thumbnail'
import { FlickityWrapper } from '@bbx/search-journey/common/components/ImageGallery/FlickityWrapper'
import { Box } from '@wh-components/core/Box/Box'
import { FlickityOptionsExtended, ImageGalleryData } from './ImageGallery.settings'
import { css } from 'styled-components'
import { ResponsiveValue } from '@wh-components/system'

interface ThumbnailsProps {
    imageData: ImageGalleryData[]
    onChange?: (index: number) => void
    isFlickityReady: boolean
    flickityThumbnailOptions: FlickityOptionsExtended
    width: ResponsiveValue<string>
}

export const Thumbnails: React.FunctionComponent<ThumbnailsProps> = ({
    imageData,
    onChange,
    isFlickityReady,
    flickityThumbnailOptions,
    width,
}) => {
    if (imageData.length === 0) {
        return null
    }

    const thumbnails = imageData.map((image, idx) => {
        const lazyload = idx !== 0

        return (
            <Thumbnail
                testId={String(idx)}
                key={`${image.thumbnailUrl}-${idx}`}
                image={image}
                index={idx}
                isFlickityReady={isFlickityReady}
                lazyLoad={lazyload}
                width={width}
            />
        )
    })

    return (
        <Box testId="gallery-thumbnail-wrapper">
            <FlickityWrapper
                flickityOptions={flickityThumbnailOptions}
                requireAsNavFor={true}
                onFlickityChange={onChange}
                css={css`
                    &.is-fullscreen {
                        .thumbnails {
                            display: none;
                        }
                    }

                    margin-top: ${(p) => p.theme.space.s}px;
                    padding-right: 24px;
                    padding-left: 24px;
                    background-color: ${(p) => p.theme.colors.semantic.surface.DEFAULT};
                    ${(p) => p.theme.media.only.phone} {
                        display: none;
                    }

                    .flickity-prev-next-button {
                        border-radius: 0;
                        background-color: transparent;
                        position: absolute;
                        top: 50%;
                        padding: 0;
                        width: 27px;

                        .flickity-button-icon {
                            width: 100%;
                            left: 0;
                            right: 0;
                        }

                        &.previous {
                            left: 0;
                        }

                        &.next {
                            right: 0;
                        }
                    }
                `}
            >
                {thumbnails}
            </FlickityWrapper>
        </Box>
    )
}
