import React from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { css } from 'styled-components'
import { Text } from '@wh-components/core/Text/Text'

export const BapAdContactFormPayLiveryBanner = () => {
    return (
        <Box
            padding="m"
            css={css`
                margin-left: -${({ theme }) => theme.space.m}px;
                margin-right: -${({ theme }) => theme.space.m}px;
                background-color: var(--wh-color-deprecated-safari15-lavender-alpha80);
                background-color: color-mix(in srgb, ${(p) => p.theme.colors.palette.lavender}, transparent 80%);
            `}
            testId="messaging-paylivery-info-box"
        >
            <Text as="p" fontWeight="bold" marginBottom="xs">
                Sicher kaufen mit willhaben-Käuferschutz
            </Text>
            <Text fontSize="s" as="p" marginVertical={{ phone: 'xs', tablet: 0 }}>
                Ist der Artikel nicht wie beschrieben oder wurde nicht verschickt, bekommst du dein Geld zurück!
            </Text>
            <Text as="p" fontSize="s" fontWeight="bold" marginTop="xs">
                <ServerRoutingAnchorLink
                    type="anchor"
                    color="palette.lavender"
                    href="https://hilfe.willhaben.at/hc/de/articles/360011484279"
                    target="_blank"
                >
                    Mehr zum Käuferschutz
                </ServerRoutingAnchorLink>
                <Text color="palette.lavender">{`  |  `}</Text>
                <ServerRoutingAnchorLink
                    color="palette.lavender"
                    type="anchor"
                    href="https://hilfe.willhaben.at/hc/de/articles/360011365340"
                    target="_blank"
                >
                    Wie funktioniert willhaben PayLivery?
                </ServerRoutingAnchorLink>
            </Text>
        </Box>
    )
}
