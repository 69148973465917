import React from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { css } from 'styled-components'

interface Props {
    current: number
    total: number
}

export const Counter: React.FunctionComponent<Props> = ({ current, total }) => (
    <React.Fragment>
        <div className="carousel-cell__overlap">
            <Box
                testId="gallery-counter"
                className="carousel-cell__overlap__counter"
                position="absolute"
                paddingLeft="s"
                paddingRight="s"
                css={css`
                    z-index: ${(p) => p.theme.zIndices.content + 1};
                    left: 0;
                    top: 0;
                    background-color: var(--wh-color-deprecated-safari15-raccoon-alpha60);
                    background-color: color-mix(in srgb, ${(p) => p.theme.colors.palette.raccoon}, transparent 60%);
                    color: ${(p) => p.theme.colors.semantic.foreground.white};
                `}
            >
                {`${current} / ${total}`}
            </Box>
        </div>
    </React.Fragment>
)
