import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { TeaserAttribute, TeaserAttributes } from '@wh/common/chapter/components/TeaserAttributes'
import { ContactButton } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/ContactButton'
import { AdDetailSaveAdButton } from '@bbx/search-journey/sub-domains/ad-detail/components/common/SaveAdButton/AdDetailSaveAdButton'
import { AdDetailStickyHeader } from '@bbx/search-journey/sub-domains/ad-detail/components/common/StickyHeader/AdDetailStickyHeader'
import { Text } from '@wh-components/core/Text/Text'
import React, { Fragment, FunctionComponent, RefObject } from 'react'
import { IconButton } from '@wh-components/core/Button/Button'
import ShareIcon from '@wh-components/icons/Share'
import { ShareButton } from '@wh/common/chapter/components/ShareButton'
import { Box } from '@wh-components/core/Box/Box'
import { PrintButton } from '@wh/common/chapter/components/PrintButton'

interface RealEstateAutoAdDetailStickyHeaderProps {
    advertDetails: AdvertDetails
    teaserAttributes: TeaserAttribute[] | null | undefined
    stickyHeaderVisibilityTrackingRef: RefObject<HTMLDivElement>
    price: string | undefined
    priceDescription: string | undefined
    contactButtonText?: string
}

export const RealEstateAutoAdDetailStickyHeader: FunctionComponent<RealEstateAutoAdDetailStickyHeaderProps> = ({
    advertDetails,
    teaserAttributes,
    stickyHeaderVisibilityTrackingRef,
    price,
    priceDescription,
    contactButtonText,
}) => {
    return (
        <AdDetailStickyHeader
            teaserAttributes={<TeaserAttributes teaserAttributes={teaserAttributes} testId="ad-detail-header-sticky-teaser-attribute" />}
            headingText={advertDetails.description}
            contactBoxes={<StickyHeaderContactBoxes advertDetails={advertDetails} contactButtonText={contactButtonText} />}
            priceBox={<StickyHeaderPriceBox priceDescription={priceDescription} price={price} />}
            stickyHeaderVisibilityTrackingRef={stickyHeaderVisibilityTrackingRef}
        />
    )
}

const StickyHeaderPriceBox: FunctionComponent<{ priceDescription: string | undefined; price: string | undefined }> = (props) => {
    if (!props.price) {
        return null
    }

    return (
        <Fragment>
            <Text fontSize="s" color="palette.raccoon" marginRight="s" testId="sticky-header-price-description">
                {props.priceDescription}
            </Text>
            <Text fontSize="xl" fontWeight="bold" color="palette.primary.main" testId="sticky-header-price">
                {props.price}
            </Text>
        </Fragment>
    )
}

const StickyHeaderContactBoxes: FunctionComponent<{
    advertDetails: AdvertDetails
    contactButtonText?: string
}> = ({ advertDetails, contactButtonText }) => {
    return (
        <Box gap="s" display="flex">
            <AdDetailSaveAdButton
                adId={advertDetails.id}
                adTitle={advertDetails.description}
                taggingData={advertDetails.taggingData}
                variant="icon"
                testId="saveAdButton-header-sticky"
                eventNameSaveAdClick="addetail_sticky_header_favorite_ad_click_save"
                eventNameUnsaveAdClick="addetail_sticky_header_favorite_ad_click_unsave"
            />
            <ShareButton
                bodyText={advertDetails.description}
                url={advertDetails.seoMetaData.canonicalUrl}
                adId={advertDetails.id}
                taggingData={advertDetails.taggingData}
                shareEmailEvent="addetail_sticky_header_share_email_click"
                shareWhatsAppEvent="addetail_sticky_header_share_whatsapp_click"
                shareFacebookEvent="addetail_sticky_header_share_facebook_click"
                shareTwitterEvent="addetail_sticky_header_share_twitter_click"
                shareLinkEvent="addetail_sticky_header_share_link_click"
                shareLinkedInEvent="addetail_share_linkedin_click"
            >
                {(toggle) => (
                    <IconButton
                        variant="outline"
                        Icon={ShareIcon}
                        name="ShareButton"
                        aria-label="Teilen"
                        onClick={toggle}
                        size={{ phone: 'medium', tablet: 'small' }}
                    />
                )}
            </ShareButton>
            <PrintButton
                testId="printButtonRealEstateAutoStickyHeader"
                id="printButtonRealEstateAutoStickyHeader"
                eventName="addetail_sticky_print_click"
                taggingData={advertDetails.taggingData}
            />
            <ContactButton
                size="small"
                adStatus={advertDetails.advertStatus}
                contactType={advertDetails.contactOption.contactType}
                eventName="addetail_sticky_header_contact_seller_click"
                taggingData={advertDetails.taggingData}
                testId="ad-detail-header-sticky-message-button"
                orgId={advertDetails.organisationDetails?.id}
                adId={advertDetails.id}
                verticalId={advertDetails.verticalId}
            >
                {contactButtonText}
            </ContactButton>
        </Box>
    )
}
