import React, { FunctionComponent, useMemo, useRef } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { Checkbox } from '@wh-components/core/FormElements/Checkbox/Checkbox'
import EyeIcon from '@wh-components/icons/Eye'
import { Card } from '@wh-components/core/Card/Card'
import { LinkWithButtonStyle } from '@wh-components/core/Button/LinkWithButtonStyle'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { Text } from '@wh-components/core/Text/Text'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { rentalRoutes } from '@wh/common/rental/lib/rentalUtils'
import type { TenantProfileDTOType } from '@wh/common/rental/types'
import { Badge } from '@wh-components/core/Badge/Badge'
import { theme } from '@wh-components/core/theme'
import coupleWithClipboardIllustrationLarge from '@bbx/static_hashed/img/real-estate/rental_couple_with_clipboard_large.svg'
import coupleWithClipboardIllustrationSmall from '@bbx/static_hashed/img/real-estate/rental_couple_with_clipboard_small.svg'
import { useScreenSize } from '@wh/common/chapter/components/UserAgentProvider/useUserAgent'
import { useRouter } from 'next/router'
import { getExpandedTenantProfileResponse } from '@wh/common/rental/api/rentalApiClient'
import { useIntersectionObserver } from '@wh/common/chapter/hooks/useIntersectionObserver'

interface ShareTenantProfileProps {
    shareTenantProfile: boolean
    tenantUuid?: string
    setCurrentShareTenantProfile: (shareTenantProfile: boolean) => void
    isCommercialAd?: boolean
    tenantProfile?: TenantProfileDTOType
    setTenantProfile: (tenantProfile: TenantProfileDTOType) => void
}

export const AdContactFormShareProfile: FunctionComponent<ShareTenantProfileProps> = ({
    shareTenantProfile,
    tenantUuid,
    setCurrentShareTenantProfile,
    isCommercialAd,
    tenantProfile,
    setTenantProfile,
}) => {
    const ref = useRef(null)
    const isCheckBoxDisabled = useMemo(() => !tenantProfile, [tenantProfile])
    const screen = useScreenSize()
    const isDesktop = screen === 'desktop'
    const { query } = useRouter()
    const seoPath = query.seopath
    const adUri = Array.isArray(seoPath) ? encodeURI(seoPath.reverse().join('/')) : ''

    useIntersectionObserver(ref, { triggerOnce: true, rootMargin: '0px 0px 50px 0px' }, [], async (entry) => {
        if (entry.isIntersecting && tenantUuid && !tenantProfile) {
            const expandedTenantProfileResponse = await getExpandedTenantProfileResponse(tenantUuid, [])
            const tenantProfileFromResponse = expandedTenantProfileResponse.tenantProfile
            if (tenantProfileFromResponse) {
                setTenantProfile(tenantProfileFromResponse)
                setCurrentShareTenantProfile(true)
            }
        }
    })

    const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked
        callActionEvent(
            isCommercialAd ? 'estate_addetail_rental_share_tenant_profile_b2c' : 'estate_addetail_rental_share_tenant_profile_c2c',
            undefined,
        )
        setCurrentShareTenantProfile(checked)
    }

    return (
        <Box ref={ref}>
            <Box width="100%" display="flex" alignItems="center" marginBottom="s">
                <Checkbox
                    name="shareTenantProfile"
                    checked={shareTenantProfile}
                    disabled={isCheckBoxDisabled}
                    label="Mietprofil teilen"
                    id="shareTenantProfile"
                    testId="share-tenant-profile-checkbox"
                    onChange={onChange}
                />
                <LinkWithButtonStyle
                    disabled={isCheckBoxDisabled}
                    size="small"
                    marginHorizontal="s"
                    variant="outline"
                    testId="previewTenantLinkButton"
                    Icon={EyeIcon}
                    href={rentalRoutes.tenantProfilePreview}
                    aria-label="Vorschau Mietprofil"
                    type="button"
                    target="_blank"
                >
                    Vorschau
                </LinkWithButtonStyle>
            </Box>
            {!tenantProfile && (
                <Card
                    width="100%"
                    alignItems="center"
                    marginVertical="m"
                    backgroundColor={theme.colors.semantic.primary.container}
                    backgroundImage={`url(${isDesktop ? coupleWithClipboardIllustrationLarge : coupleWithClipboardIllustrationSmall})`}
                    backgroundRepeat="no-repeat"
                    border="none"
                    backgroundPosition={{ phone: '100% 30px', tablet: '100% -60%', desktop: '90% 30px' }}
                    position="relative"
                    overflow="clip"
                >
                    <Badge fontSize="xs" fontWeight="bold" lineHeight="1.2">
                        NEU
                    </Badge>
                    <br />
                    <Box marginTop="xs" maxWidth="65%">
                        <Text as="p" fontWeight="bold">
                            Kennst du schon das Mietprofil?
                        </Text>
                        <Text as="p" fontSize="m" marginTop={{ phone: 's', tablet: 0 }}>
                            Teile deine wichtigsten Infos mit einem Klick und stich bei Vermieter:innen heraus!
                        </Text>
                        <ClientRoutingAnchorLink
                            type="button"
                            variant="outline"
                            size="small"
                            href={adUri ? `${rentalRoutes.tenantProfile}?adUri=${adUri}` : `${rentalRoutes.tenantProfile}`}
                            marginTop="s"
                            testId="myTenantProfileLinkTestId"
                            onClick={() => {
                                callActionEvent(
                                    isCommercialAd
                                        ? 'estate_addetail_rental_share_create_tenant_profile_b2c'
                                        : 'estate_addetail_rental_share_create_tenant_profile_c2c',
                                    undefined,
                                )
                            }}
                        >
                            {tenantUuid ? 'Jetzt erstellen' : 'Einloggen'}
                        </ClientRoutingAnchorLink>
                    </Box>
                </Card>
            )}
        </Box>
    )
}
