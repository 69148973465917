import React, { FunctionComponent, useMemo } from 'react'
import { SpaceProps } from '@wh-components/system/space'
import { LayoutProps } from '@wh-components/system/layout'
import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { getContactData } from '@bbx/search-journey/sub-domains/ad-detail/lib/getContactData'
import { Card } from '@wh-components/core/Card/Card'
import { Divider } from '@wh-components/core/Divider/Divider'
import { KeyValue } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/KeyValuePair'
import { DealerProfile } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/DealerProfile'
import { AddressBox } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/AddressBox'
import { WebsiteLink } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/WebsiteLink'
import { PhoneNumberButton } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/PhoneNumberButton'
import { SellerInfoButton } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/SellerInfoButton'
import { getOrganisationProfileWeblink } from '@bbx/search-journey/sub-domains/ad-detail/lib/getLinkToDealerProfile'
import { Box } from '@wh-components/core/Box/Box'

interface AutoMotorContactBoxDealerBottom extends SpaceProps, LayoutProps {
    advertDetails: AdvertDetails
}

export const AutoMotorContactBoxDealerBottom: FunctionComponent<AutoMotorContactBoxDealerBottom> = ({ advertDetails, ...props }) => {
    const { name, image, websiteUrl, companyYears, address, contactName, phoneNumbers } = useMemo(
        () => getContactData(advertDetails),
        [advertDetails],
    )

    const organisationProfileWeblink = getOrganisationProfileWeblink(advertDetails.organisationDetails.organisationDetailLinkList)

    return (
        <Card padding={0} backgroundColor="semantic.surface" testId="ad-detail-contact-box-dealer-bottom" {...props}>
            <DealerProfile
                name={name}
                image={image}
                link={websiteUrl}
                companyYears={companyYears}
                adId={advertDetails.id}
                taggingData={advertDetails.taggingData}
                testIdPrefix="bottom"
            />
            <Divider marginHorizontal="m" />
            <Box display="flex" flexDirection="column" gap="s" padding="m">
                <KeyValue
                    title="Adresse"
                    testIdPrefix="contact-box-dealer-bottom"
                    content={address && <AddressBox address={address} testIdPrefix="bottom" />}
                />
                <KeyValue title="Kontakt" testIdPrefix="contact-box-dealer-bottom" content={contactName} />
                <KeyValue title="Referenz ID" testIdPrefix="contact-box-dealer-bottom" content={advertDetails.advertiserReferenceNumber} />
                {websiteUrl && <WebsiteLink url={websiteUrl} adId={advertDetails.id} taggingData={advertDetails.taggingData} />}
            </Box>
            <Box display="flex" flexDirection="column" gap="s" padding="m" paddingTop={0}>
                <PhoneNumberButton
                    phoneNumbers={phoneNumbers}
                    toggleWithContext={true}
                    adId={advertDetails.id}
                    taggingData={advertDetails.taggingData}
                    testIdPrefix="bottom"
                    orgId={advertDetails.organisationDetails?.id}
                    price={advertDetails.taggingData.tmsDataValues.tmsData.price}
                    verticalId={advertDetails.verticalId}
                />
                {organisationProfileWeblink && (
                    <SellerInfoButton
                        link={organisationProfileWeblink}
                        clickTaggingActionEvent="addetail_dealer_profile_click"
                        taggingData={advertDetails.taggingData}
                        testIdPrefix="bottom"
                    >
                        Mehr über diesen Händler erfahren
                    </SellerInfoButton>
                )}
            </Box>
        </Card>
    )
}
