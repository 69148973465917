import { css } from 'styled-components'

// workaround for Firefox bug: https://bugzilla.mozilla.org/show_bug.cgi?id=1436758
export const printButtonTextColorFirefoxWorkaroundCss = css`
    @supports (-moz-appearance: meterbar) {
        @media print {
            color: ${(p) => p.theme.colors.palette.primary.main};
            background: ${(p) => p.theme.colors.semantic.surface.DEFAULT};
        }
    }
`
