import React, { FunctionComponent, useContext, useState } from 'react'
import { SpaceProps } from '@wh-components/system/space'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { PhoneNumberContext } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/PhoneNumberContext'
import { DmpStateContext } from '@wh/common/chapter/components/DmpStateProvider/DmpStateProvider'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { Button, ButtonProps, ContainerProps } from '@wh-components/core/Button/Button'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { logBdsEvent } from '@wh/common/chapter/api/bdsApiClient'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import PhoneIcon from '@wh-components/icons/Call'
import { FacebookPixelContacted } from '@bbx/common/components/FacebookPixel/FacebookPixel'
import { GoogleConversionTrackingPixelContacted } from '@bbx/common/components/GoogleConversionTrackingPixel/GoogleConversionTrackingPixel'
import { ButtonWithLinkStyle } from '@wh-components/core/Button/ButtonWithLinkStyle'
import { TypographyProps } from '@wh-components/system/typography'

interface PhoneNumberButtonProps extends SpaceProps {
    phoneNumbers: string[]
    faxNumber?: string
    toggleWithContext?: boolean
    adId: string
    taggingData: TaggingData
    testIdPrefix?: string
    variant?: 'button' | 'link'
    orgId: number
    price?: string
    verticalId: number
}

export const PhoneNumberButton: FunctionComponent<PhoneNumberButtonProps> = ({
    phoneNumbers,
    faxNumber,
    toggleWithContext = false,
    adId,
    taggingData,
    testIdPrefix,
    variant = 'button',
    orgId,
    price,
    verticalId,
    ...props
}) => {
    const [stateShow, setStateShow] = useState(false)
    const [renderContactedMetaAndGooglePixels, setRenderContactedMetaAndGooglePixels] = useState(false)

    const [contextShow, setContextShow, setAdvertisingStateContacted] = useContext(PhoneNumberContext)
    const show = toggleWithContext ? contextShow : stateShow

    const { trackCustomDmpEvent } = useContext(DmpStateContext)

    if (!phoneNumbers.length) {
        return null
    }

    const ButtonComponent = variant === 'button' ? Button : ButtonWithLinkStyle
    const buttonProps: ButtonProps & ContainerProps & TypographyProps =
        variant === 'button'
            ? {
                  size: 'large',
                  variant: 'outline',
                  Icon: PhoneIcon,
                  width: '100%',
              }
            : {
                  fontSize: 'm',
              }

    return (
        <Box {...props}>
            {renderContactedMetaAndGooglePixels && (
                <>
                    <FacebookPixelContacted adId={adId} orgId={orgId} price={price} verticalId={verticalId} />
                    <GoogleConversionTrackingPixelContacted verticalId={verticalId} />
                </>
            )}
            {!show ? (
                <ButtonComponent
                    {...buttonProps}
                    display="inline-flex"
                    aria-label="Telefonnummern anzeigen"
                    testId={`${testIdPrefix}-contact-box-phone-number-button`}
                    onClick={() => {
                        toggleWithContext ? setContextShow(true) : setStateShow(true)
                        setAdvertisingStateContacted()
                        setRenderContactedMetaAndGooglePixels(true)
                        trackCustomDmpEvent('UserAction', { eventName: 'TapCallSeller' })
                        logBdsEvent(adId, 'phone-number-button-clicked')
                        return callActionEvent('addetail_show_phone_number_click', taggingData)
                    }}
                >
                    {phoneNumbers[0].substring(0, 3)}... Nummer anzeigen
                </ButtonComponent>
            ) : (
                <Box
                    minHeight={48}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    testId={`${testIdPrefix}-contact-box-phone-number-box`}
                >
                    {phoneNumbers.map((phoneNumber, index) => (
                        <Box key={index}>
                            <Text fontWeight="semibold" display="inline-block" width={80} marginRight="s">
                                Telefon {phoneNumbers.length > 1 && index + 1}
                            </Text>
                            <ServerRoutingAnchorLink
                                type="anchor"
                                href={`tel:${phoneNumber}`}
                                color="palette.verydarkgrey"
                                aria-label="Anbieter Anrufen"
                                testId={`${testIdPrefix}-contact-box-phone-number-${index + 1}`}
                                onClick={() => {
                                    trackCustomDmpEvent('UserAction', { eventName: 'TapCallSeller' })
                                    setRenderContactedMetaAndGooglePixels(true)
                                    logBdsEvent(adId, 'call')
                                    return callActionEvent('addetail_call_phone_click', taggingData)
                                }}
                            >
                                {phoneNumber}
                            </ServerRoutingAnchorLink>
                        </Box>
                    ))}
                    {faxNumber && (
                        <Box>
                            <Text fontWeight="semibold" display="inline-block" width={80} marginRight="s">
                                Faxnummer
                            </Text>
                            {faxNumber}
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    )
}
