import { AdvertDetailsWithFormattedAttributes } from '@bbx/common/types/ad-detail/AdvertDetails'
import { AddressBox } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/AddressBox'
import { ContactButton } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/ContactButton'
import { DealerProfile } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/DealerProfile'
import { PhoneNumberButton } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/PhoneNumberButton'
import { PriceBox } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/PriceBox'
import { TextLinks } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/TextLinks'
import { getContactData } from '@bbx/search-journey/sub-domains/ad-detail/lib/getContactData'
import { Box } from '@wh-components/core/Box/Box'
import { Card } from '@wh-components/core/Card/Card'
import { Divider } from '@wh-components/core/Divider/Divider'
import { Text } from '@wh-components/core/Text/Text'
import { LayoutProps } from '@wh-components/system/layout'
import { SpaceProps } from '@wh-components/system/space'
import React, { Fragment, FunctionComponent, useMemo } from 'react'
import { NowOpenButton } from './NowOpenButton'
import { SellerInfoButton } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/SellerInfoButton'
import { WebsiteLink } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/WebsiteLink'
import { getAutoMotorAdvertPrice } from '@bbx/search-journey/common/lib/getAutoMotorAdvertPrice'
import { ButtonWithLinkStyle } from '@wh-components/core/Button/ButtonWithLinkStyle'
import { scrollToElement } from '@wh/common/chapter/lib/commonHelpers'
import { useResponsiveValue } from '@wh-components/core/utilities/responsive'
import { FIXED_HEADER_HEIGHT_PHONE } from '@wh/common/chapter/lib/config/constants'
import { getOrganisationProfileWeblink } from '@bbx/search-journey/sub-domains/ad-detail/lib/getLinkToDealerProfile'

interface AutoMotorContactBoxDealerTopProps extends SpaceProps, LayoutProps {
    advertDetails: AdvertDetailsWithFormattedAttributes
}

export const AutoMotorContactBoxDealerTop: FunctionComponent<AutoMotorContactBoxDealerTopProps> = ({ advertDetails, ...props }) => {
    const { name, image, websiteUrl, companyYears, address, nowOpen, phoneNumbers, activeAdCount } = useMemo(
        () => getContactData(advertDetails),
        [advertDetails],
    )

    const showDetailsButton =
        advertDetails.formattedAttributes.attribute
            .map((a) => a.name)
            .find((key) => ['LEASING/DETAILS', 'LEASING/MONTHLY_RATE'].includes(key)) !== undefined

    const { mainPrice, additionalPrices } = useMemo(
        () => getAutoMotorAdvertPrice(advertDetails.advertStatus, advertDetails.formattedAttributes),
        [advertDetails],
    )

    const headerHeight = useResponsiveValue({ phone: FIXED_HEADER_HEIGHT_PHONE, tablet: 100 }, 0)

    const organisationProfileWeblink = getOrganisationProfileWeblink(advertDetails.organisationDetails.organisationDetailLinkList)

    return (
        <Card padding={0} testId="ad-detail-contact-box-dealer-top" {...props}>
            <Box display="flex" flexDirection="column" gap="xs" padding="m">
                <PriceBox
                    mainPrice={mainPrice}
                    additionalPrices={additionalPrices}
                    priceLabelDisplay="row"
                    detailsButton={
                        showDetailsButton ? (
                            <Fragment>
                                <Text color="semantic.outline">|</Text>
                                <ButtonWithLinkStyle
                                    testId="leasing-details-button"
                                    onClick={() =>
                                        scrollToElement(document.getElementById('leasing-information-box'), 'start', 'always', headerHeight)
                                    }
                                >
                                    Details
                                </ButtonWithLinkStyle>
                            </Fragment>
                        ) : undefined
                    }
                />
                <TextLinks landingPageLinks={advertDetails.adServiceDesktopLandingPageLinks} />
            </Box>
            <Box display={{ tablet: 'none' }}>
                <Divider marginHorizontal="m" />
                <Box display="flex" flexDirection="column" gap="xs" padding="m">
                    <Text fontWeight="semibold">{name}</Text>
                    {address && <AddressBox address={address} testIdPrefix="mobile-top" />}
                    <Box display="flex" justifyContent="space-between">
                        {nowOpen && <NowOpenButton taggingData={advertDetails.taggingData} />}
                        {websiteUrl && (
                            <WebsiteLink url={websiteUrl} adId={advertDetails.id} taggingData={advertDetails.taggingData} fontSize="s" />
                        )}
                    </Box>
                </Box>
            </Box>
            <Box display={{ phone: 'none', tablet: 'block' }}>
                <Divider />
                <DealerProfile
                    name={name}
                    image={image}
                    companyYears={companyYears}
                    adId={advertDetails.id}
                    taggingData={advertDetails.taggingData}
                    testIdPrefix="top"
                    dealerImageMaxHeight={65}
                />
                <Divider />
                <Box display="flex" flexDirection="column" gap="s" padding="m">
                    <ContactButton
                        contactType={advertDetails.contactOption.contactType}
                        taggingData={advertDetails.taggingData}
                        adStatus={advertDetails.advertStatus}
                        orgId={advertDetails.organisationDetails?.id}
                        adId={advertDetails.id}
                        verticalId={advertDetails.verticalId}
                    >
                        Händler kontaktieren
                    </ContactButton>
                    <PhoneNumberButton
                        phoneNumbers={phoneNumbers}
                        toggleWithContext={true}
                        adId={advertDetails.id}
                        taggingData={advertDetails.taggingData}
                        testIdPrefix="top"
                        orgId={advertDetails.organisationDetails?.id}
                        price={advertDetails.taggingData.tmsDataValues.tmsData.price}
                        verticalId={advertDetails.verticalId}
                    />

                    {organisationProfileWeblink && (
                        <SellerInfoButton
                            link={organisationProfileWeblink}
                            clickTaggingActionEvent="addetail_dealer_profile_click"
                            taggingData={advertDetails.taggingData}
                            testIdPrefix="top"
                            activeAdCount={activeAdCount}
                        >
                            Mehr von diesem Händler
                        </SellerInfoButton>
                    )}
                </Box>
                <Box display="flex" flexDirection="column" gap="s" padding="m" paddingTop={0}>
                    {address && <AddressBox address={address} testIdPrefix="top" />}
                    <Box display="flex" justifyContent="space-between">
                        {nowOpen && <NowOpenButton taggingData={advertDetails.taggingData} />}
                        {websiteUrl && (
                            <WebsiteLink url={websiteUrl} adId={advertDetails.id} taggingData={advertDetails.taggingData} fontSize="s" />
                        )}
                    </Box>
                </Box>
            </Box>
        </Card>
    )
}
